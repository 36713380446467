<template>
  <div class="merchant-add">
    <GlobalChunk icon="add" :title="$route.query.id ? '编辑商家' : '添加商家'" :padding="[32, 0, 95, 55]">
      <el-row>
        <el-col :span="12">
          <GlobalForm :init-data="initData" :form-rules="formRules" :form-item-list="formItemList"
            @handleConfirm="handleConfirm" confirmBtnName="提交">
            <template v-slot:operation>
              <p v-if="$route.query.id" class="phone_tip lv_fc_red">说明：联系电话即登录手机号，修改后创建人将退出登录，请谨慎操作</p>
              <el-form-item class="el_from" label="省市区" :required="true">
                <Provinces @areaID="areaChild" @provinceID="provinceChild" @cityID="cityChild" :addressInfo="addressInfo"
                  :optionsProvince="optionsProvince" :optionsArea="optionsArea" :optionsCity="optionsCity" />
              </el-form-item>
            </template>
            <!-- <el-form-item class="el_from" label="保障金缴纳:" slot="payDeposit" :required="true">
              <el-input maxlength="8" v-model="initData.payDeposit" placeholder="输入保障金缴纳"></el-input>
            </el-form-item> -->

            <el-button slot="btnSlot" @click="FromBack">返回</el-button>
          </GlobalForm>
        </el-col>
      </el-row>
      <el-dialog title="确认提示" :visible.sync="confimShow" width="400px" :before-close="() => confimShow = false">
        <div style="text-align: center;padding-bottom: 20px">是否确认修改商户信息</div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confimShow = false">取 消</el-button>
          <el-button type="primary" @click="sumitclick">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="提交成功提醒" :visible.sync="successfullShow" width="500px" :before-close="closesuccs">
        <div style="text-align: center;font-weight: bold;">提交成功！</div>
        <div v-if="!updateCodedata.contactIsUpdate && !updateCodedata.phoneIsUpdate"
          style="text-align: center;padding-bottom: 20px;font-weight: bold;">创建人姓名及手机号未变更。</div>
        <div v-else style="text-align: center;padding-bottom: 20px;font-weight: bold;">创建人姓名账号已修改成功！小程序登录信息已重置，需重新登录。
        </div>
        <div v-if="updateCodedata.contactIsUpdate || updateCodedata.phoneIsUpdate">
          <div style="margin-bottom: 10px;">
            <p>创建人姓名：{{ updateCodedata.contactBefore }}{{ updateCodedata.contactAfter ? '→' : '' }}<span
                style="color: red;">{{ updateCodedata.contactAfter }}</span></p>
          </div>
          <div style="margin-bottom: 10px;">
            <p>创建人登录手机号：{{ updateCodedata.contactPhoneBefore }}{{ updateCodedata.contactPhoneAfter ? '→' : '' }}<span
                style="color: red;">{{ updateCodedata.contactPhoneAfter }}</span></p>
          </div>
          <div style="margin-bottom: 10px;">
            <p v-if="!updateCodedata.storePcAccFailMsg">门店后台登录账号：{{ updateCodedata.storePcAccBefore||'--' }}{{ updateCodedata.storePcAccAfter ? '→' : '' }}<span
                style="color: red;">{{ updateCodedata.storePcAccAfter }}</span></p>
            <p v-else>门店后台登录账号：<span style="color: red;">{{ updateCodedata.storePcAccFailMsg }}</span></p>
          </div>
          <div style="margin-bottom: 10px;">
            <p v-if="!updateCodedata.withdrawFailMsg">财务提现人：{{ updateCodedata.withdrawBefore }}{{ updateCodedata.withdrawAfter ? '→' : '' }}<span
                style="color: red;">{{ updateCodedata.withdrawAfter }}</span></p>
            <p v-else>财务提现人：{{ updateCodedata.withdrawFailMsg }}</p>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closesuccs">关闭</el-button>
        </span>
      </el-dialog>
    </GlobalChunk>
  </div>
</template>

<script>
import {
  formValidateMobile,
  formValidateNumber,
  formValidateNumberEight,
} from '@/utils/form-validate'
import Provinces from '@/components/plugins/provinces'
import _api from '@/utils/request'
import GlobalForm from "@/components/global/components/GlobalForm.vue";
import GlobalChunk from "@/components/global/components/GlobalChunk.vue";

export default {
  name: 'merchant-add',
  components: { GlobalChunk, GlobalForm, Provinces },
  data() {
    // 添加的初始参数
    const addInitData = {
      operator: '',
      contact: '',
      phone: '',
      province: '',
      address: '',
      payDeposit: '',
      paymentType: '01',
      businessLicense: '',
      merchantVividImgs: [],
      companyLogo: '',
    };
    let addressInfo = {
      provinceID: '',
      cityID: '',
      areaID: '',
    }
    // 省市区数据
    let optionsProvince = [];
    let optionsCity = [];
    let optionsArea = [];
    let initData = JSON.parse(window.sessionStorage.getItem('storeShopEditStore'));
    const MerchantFormData = [];
    if (initData) {
      // 区域
      addressInfo = {
        provinceID: initData.provinceId ? initData.provinceId : '',
        cityID: initData.cityId ? initData.cityId : '',
        areaID: initData.regionId ? initData.regionId : '',
      }
      MerchantFormData.provinceId = initData.provinceId ? initData.provinceId : '';
      MerchantFormData.cityId = initData.cityId ? initData.cityId : '';
      if (initData.provinceId ?? false) {
        optionsProvince.push({
          id: initData.provinceId,
          // cityName: initData.province
        })
      }

      if (initData.cityId ?? false) {
        optionsCity.push({
          id: initData.cityId,
          // cityName: initData.city
        })
      }

      if (initData.regionId ?? false) {
        optionsArea.push({
          id: initData.regionId,
          // cityName: initData.region
        })
      }
    } else {
      initData = addInitData;
    }
    return {
      updateCodedata: {
          "contactIsUpdate": false, 
          "phoneIsUpdate": false, 
          "contactBefore": null, 
          "contactAfter": null, 
          "contactPhoneBefore": null, 
          "contactPhoneAfter": null,
           "storePcAccIsUpdate": false, 
           "storePcAccFailMsg": null, 
           "storePcAccBefore": null, 
           "storePcAccAfter": null, 
           "withdrawIsUpdate": false, 
           "withdrawFailMsg": null, 
           "withdrawBefore": null,
            "withdrawAfter": null
        },
      pramsedata: {},
      successfullShow: false,
      confimShow: false,
      id: "",
      sumitdata: '',
      goolgeShow: false,
      // ShowUserAccount: false,
      MerchantFormData,
      optionsProvince,
      optionsCity,
      optionsArea,
      addressInfo,
      initData: initData,
      formItemList: [
        {
          key: 'companyName',
          type: 'input',
          labelName: '商家名称',
          placeholder: '输入商家名称',
          maxlength: '100'
        },
        {
          key: 'operator',
          type: 'select',
          labelName: '运营商',
          placeholder: '选择运营商',
          isNumber: '600',

          option: [
            { label: '移动', value: '01' }, { label: '联通', value: '02' }, { label: '电信', value: '03' }, {
              label: '其他',
              value: '04'
            }
          ]
        },
        {
          key: 'contact',
          type: 'input',
          labelName: '联系人',
          placeholder: '创建人名称（10字内）',
        },
        {
          key: 'phone',
          isNumber: true,
          type: 'input',
          labelName: '联系电话',
          placeholder: '联系电话同时为商家创建人登录手机号',
        },
        { slotName: 'operation' },
        {
          key: 'address',
          type: 'input',
          labelName: '商家地址',
          placeholder: '输入商家详细地址（限50字内）',
        },
        {
          key: 'companyLogo',
          type: 'upload',
          labelName: '商家logo',
          placeholder:
            '最多支持上传1张图片，大小不超过2M，格式为JPG或PNG,建议尺寸100*100px',
        },
        {
          key: 'businessLicense',
          type: 'upload',
          labelName: '营业执照',
          placeholder:
            '最多支持上传1张图片，大小不超过2M，格式为JPG或PNG,建议尺寸100*100px',
        },
      ],
      formRules: {
        companyName: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入商家名称'
          },
        ],
        operator: [
          {
            required: true,
            trigger: 'blur',
            message: '请选择运营商',
            max: 4,
          },
        ],
        contact: [
          {
            required: true,
            trigger: 'blur',
            message: '输入联系人名称（10字内）',
            max: 10,
          },
        ],
        phone: [
          {
            required: true,
            validator: formValidateMobile,
            trigger: 'blur',
          },
        ],
        address: [
          {
            required: true,
            trigger: 'blur',
            message: '输入商家详细地址（限50字内）',
            max: 50,
          },
        ],
        businessLicense: [
          {
            required: true,
            trigger: "blur",
            message: "请上传商家营业执照"
          }
        ],
       
      },
    }
  },
  created() {
  },
  mounted() {
    const id = this.$route.query.id;
    this.id = id;
    if (id) {
      this.provincesMount();
    } else {
      _api.listAudit(0).then((res) => {
        if (res.code === 1) {
          this.optionsProvince = res.data
        }
      });
    }
  },
  methods: {
    closesuccs() {
      this.$router.push({
        path: "/StoreShop/index"
      })
    },
    provincesMount() {
      _api.listAudit(0).then((res) => {
        if (res.code === 1) {
          this.optionsProvince = res.data;
        }
      })
      _api.listAudit(this.MerchantFormData.provinceId).then((res) => {
        this.optionsCity = res.data
      })
      _api.listAudit(this.MerchantFormData.cityId).then((res) => {
        this.optionsArea = res.data
      })
    },
    areaChild(val) {
      this.addressInfo.areaID = val
    },
    provinceChild(val) {
      this.addressInfo.provinceID = val
      this.addressInfo.cityID = ''
      this.addressInfo.areaID = ''
      _api.listAudit(val).then((res) => {
        if (res.code === 1) {
          this.optionsCity = res.data
        }
      })
    },
    cityChild(val) {
      this.addressInfo.cityID = val
      _api.listAudit(val).then((res) => {
        if (res.code === 1) {
          this.optionsArea = res.data
        }
      })
    },
    handleConfirm(data) {
      console.log(data)
      this.sumitdata = data
      const vo = {
        companyName: data.companyName,
        provinceId: this.addressInfo.provinceID,
        cityId: this.addressInfo.cityID,
        regionId: this.addressInfo.areaID,
        operator: data.operator,
        contact: data.contact,
        phone: data.phone,
        address: data.address,
        businessLicense: data.businessLicense,
        merchantVividImgs: data.merchantVividImgs,
        companyLogo: data.companyLogo,
        merchantType: '02',
      }
      if (!data.phone) {
        return this.$message.error('请输入手机号')
      } else if (!this.addressInfo.areaID) {
        return this.$message.error('请选择省市区')
      }
      this.pramsedata = vo
      this.sumitclick();
    },
    //提交按钮
    sumitclick() {
      // 请求
      let rq;
      // 编辑
      if (this.id) {
        // 已确认，开始请求
        if (this.confimShow) {
          this.pramsedata.id = this.id;
          rq = _api.companyEdit(this.pramsedata);
        } else {
          return this.confimShow = true;
        }
      } else {
        // 添加
        rq = _api.companyadd(this.pramsedata);
      }
      rq.then((res) => {
        if (res.code === 1) {
          window.sessionStorage.removeItem("storeShopEditStore");
          //编辑
          if (this.id) {
            this.updateCodedata = res.data
            this.successfullShow = true
          } else {
            //添加
            this.$router.push({ path: '/StoreShop/index' })
          }
          this.goolgeShow = false
          this.$message({
            message: this.id ? '编辑成功' : '添加成功',
            type: 'success',
          })
        } else {
          this.$message({
            message: res.msg,
            type: 'error',
          })
        }
      })
    },
    BeautifulClosed() {
      this.goolgeShow = false
    },
    FromBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.merchant-add {
  .el_from {
    margin-left: -120px;
  }
}

.el-dialog__body .acc_dialog_text1 {
  margin-bottom: 40px;
  color: red;
}

.command {
  display: flex;
  align-items: center;
}

.command {
  display: flex;
  align-items: center;
}

.command .el-input {
  width: 80%;
}

/deep/ .el-select {
  width: 100%;
}

.phone_tip {
  font-size: 12px;
  transform: translate(15px, -10px);
}</style>
